import { Tag, Tooltip } from 'antd';

import { useTranslation } from 'react-i18next';
import { Order, StatusType } from 'model/Order';
import Monetary from 'components/UI/Data/Monetary';
import { generateCode, roundDecimals, showAt } from 'utils/util';
import { dateUtils } from 'utils/date';
import { useCallback, useMemo } from 'react';
import ShippingFee from 'components/UI/Data/ShippingFee';
import { statusMap } from '../maps/status';
import { PrintableMyOrdersTable } from './style';

interface Props {
  row: Order[];
}

export default function NestedTable({ row }: Props) {
  const { t: translate } = useTranslation();

  const producerColumn = useMemo(
    () => (
      <span style={{ fontSize: 8 }}>
        {translate('myOrders.orderType.table.producer')} <br />{' '}
        {translate('myOrders.orderType.table.producerCode')}
      </span>
    ),
    [],
  );
  const productColumn = useMemo(
    () => (
      <span style={{ fontSize: 8 }}>
        {translate('myOrders.orderType.table.product')} <br />{' '}
        {translate('myOrders.orderType.table.productCode')}
      </span>
    ),
    [],
  );

  const packValueColumn = useMemo(
    () => (
      <span style={{ fontSize: 8 }}>
        {translate('myOrders.orderType.table.embValue')} <br />{' '}
        {translate('myOrders.orderType.table.valueEmb')}
      </span>
    ),
    [],
  );

  const qeQpeTotalColumn = useMemo(
    () => (
      <span style={{ fontSize: 8 }}>
        {translate('myOrders.orderType.table.qtdUnit')} <br />{' '}
        {translate('myOrders.orderType.table.totalUnit')}
      </span>
    ),
    [],
  );
  const vlrUnitTotalColumn = useMemo(
    () => (
      <span style={{ fontSize: 8 }}>
        {translate('myOrders.orderType.table.vlrUnit')} <br />{' '}
        {translate('myOrders.orderType.table.vlrTotal')}
      </span>
    ),
    [],
  );
  const vlrTotalUnitColumn = useMemo(
    () => (
      <span style={{ fontSize: 8 }}>
        {translate('myOrders.orderType.table.vlrUnitTotal')} <br />{' '}
        {translate('myOrders.orderType.table.vlrTotal')}
      </span>
    ),
    [],
  );
  const vlrShippingFeeColumn = useMemo(
    () => (
      <span style={{ fontSize: 8 }}>
        {translate('myOrders.orderType.table.shippingUnit')} <br />{' '}
        {translate('myOrders.orderType.table.shippingTotal')}
      </span>
    ),
    [translate],
  );
  const renderText = useCallback(
    (title: any) => <span style={{ fontSize: 8 }}>{title}</span>,
    [],
  );

  return (
    <PrintableMyOrdersTable dataSource={row} pagination={false}>
      <PrintableMyOrdersTable.Column
        title={renderText(translate('myOrders.orderType.table.status'))}
        dataIndex="status"
        key="status"
        align="left"
        render={(status: StatusType, order: Order) => {
          if (statusMap[status]) {
            const { color, description } = statusMap[status];
            return renderText(
              <Tooltip title={order.resultMessage}>
                <Tag color={color}>{description}</Tag>
              </Tooltip>,
            );
          }
          return renderText(status);
        }}
      />

      <PrintableMyOrdersTable.Column
        title={renderText(translate('myOrders.orderType.table.key'))}
        dataIndex="accountCode"
        key="accountCode"
        align="left"
        render={(accountCode: number) => renderText(accountCode)}
      />
      <PrintableMyOrdersTable.Column
        title={renderText(translate('myOrders.orderType.table.orderPrint'))}
        dataIndex="orderNumber"
        key="orderNumber"
        align="left"
        render={(orderNumber: number) => renderText(orderNumber)}
      />
      <PrintableMyOrdersTable.Column
        title={renderText(translate('myOrders.orderType.table.saleType'))}
        dataIndex="saleType"
        key="saleType"
        align="left"
        render={(saleType: string) => renderText(saleType)}
      />

      <PrintableMyOrdersTable.Column
        title={renderText(translate('myOrders.orderType.table.orderDate'))}
        dataIndex="saleConclusion"
        key="saleConclusion"
        align="left"
        render={(saleConclusion: string) =>
          renderText(dateUtils.formatDate(saleConclusion, 'DD/MM/YYYY HH:mm'))
        }
      />
      <PrintableMyOrdersTable.Column
        title={renderText(translate('myOrders.orderType.table.deliveryDate'))}
        dataIndex="deliveryDate"
        key="deliveryDate"
        align="left"
        render={(deliveryDate: string, order: Order) => {
          if (!order.isLKPSale) {
            return renderText(dateUtils.formatDate(deliveryDate, 'DD/MM/YYYY'));
          }
          return '-';
        }}
      />

      <PrintableMyOrdersTable.Column
        title={renderText(translate('myOrders.orderType.table.billingDate'))}
        dataIndex="billingDate"
        key="billingDate"
        align="left"
        render={(billingDate: string) =>
          renderText(dateUtils.formatDate(billingDate, 'DD/MM/YYYY'))
        }
      />
      <PrintableMyOrdersTable.Column
        title={producerColumn}
        dataIndex="siteName"
        key="siteName"
        align="left"
        render={(siteName: string, order: Order) =>
          renderText(
            <>
              {showAt(siteName, 15)} <br /> {order.siteCode}
            </>,
          )
        }
      />

      <PrintableMyOrdersTable.Column
        title={renderText(translate('myOrders.orderType.table.barcode'))}
        dataIndex="productBarCode"
        key="productBarCode"
        align="left"
        render={(productBarCode: number) => renderText(productBarCode)}
      />
      <PrintableMyOrdersTable.Column
        title={productColumn}
        dataIndex="description"
        key="description"
        align="left"
        render={(description: string, order: Order) =>
          renderText(
            <>
              {showAt(description, 25)} <br />{' '}
              {generateCode(order.productCode, order.erpCode)}
            </>,
          )
        }
      />

      <PrintableMyOrdersTable.Column
        title={renderText(translate('myOrders.orderType.table.quality'))}
        dataIndex="quality"
        key="quality"
        align="left"
        render={(quality: string) => renderText(quality)}
      />
      <PrintableMyOrdersTable.Column
        title={packValueColumn}
        dataIndex="packingName"
        key="packingName"
        align="left"
        render={(packingName: string, order: Order) =>
          renderText(
            <>
              {packingName}
              <br /> <Monetary symbol value={order.packingPrice} />{' '}
            </>,
          )
        }
      />
      <PrintableMyOrdersTable.Column
        title={qeQpeTotalColumn}
        dataIndex="packingQuantity"
        key="packingQuantity"
        align="left"
        render={(packingQuantity: number, order: Order) =>
          renderText(
            <>
              {packingQuantity} x {order.unitPerPacking} <br />{' '}
              {order.unitQuantity}
            </>,
          )
        }
      />

      <PrintableMyOrdersTable.Column
        title={vlrUnitTotalColumn}
        dataIndex="unitPrice"
        key="unitPrice"
        align="left"
        render={(unitPrice: number, order: Order) =>
          renderText(
            <>
              <Monetary symbol value={unitPrice} />
              <br />
              <Monetary symbol value={order.subTotal} />
            </>,
          )
        }
      />
      <PrintableMyOrdersTable.Column
        title={vlrShippingFeeColumn}
        dataIndex="shippingFee"
        key="shippingFee"
        align="left"
        render={(shippingFee: number, order: Order) =>
          renderText(
            <>
              <ShippingFee
                value={roundDecimals(shippingFee / order.unitQuantity, 6)}
              />
              <br />
              <ShippingFee value={shippingFee} />
            </>,
          )
        }
      />
      <PrintableMyOrdersTable.Column
        title={renderText(translate('myOrders.orderType.table.addDisc'))}
        dataIndex="voucherAmountLabel"
        key="voucherAmountLabel"
        align="left"
        render={(voucherAmountLabel: string) => renderText(voucherAmountLabel)}
      />
      <PrintableMyOrdersTable.Column
        title={vlrTotalUnitColumn}
        dataIndex="unitPriceConsolidated"
        key="unitPriceConsolidated"
        align="left"
        render={(unitPriceConsolidated: number, order: Order) =>
          renderText(
            <>
              <Monetary symbol value={unitPriceConsolidated} />
              <br />
              <Monetary symbol value={order.total} />
            </>,
          )
        }
      />

      <PrintableMyOrdersTable.Column
        title={renderText(translate('myOrders.orderType.table.observation'))}
        dataIndex="observation"
        key="observation"
        render={(observation: string) =>
          renderText(showAt(observation ?? '', 15))
        }
        align="left"
      />
    </PrintableMyOrdersTable>
  );
}
