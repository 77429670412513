import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Monetary from 'components/UI/Data/Monetary';
import {
  BgColorsOutlined,
  DeleteOutlined,
  ExpandOutlined,
  GiftOutlined,
  GroupOutlined,
  LeftOutlined,
  RightOutlined,
  TrophyOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Col, Input, Row, Select } from 'antd';
import ImageGallery from 'components/UI/ImageGallery';
import { cartSelectors, sessionSelectors } from 'store/state/selectors';
import { useSelector } from 'react-redux';
import Packing from 'model/Packing';
import ProductOffer from 'model/ProductOffer';
import { PostBodyProductInCart } from 'model/types';
import { offerTypeMap } from 'enums/offerTypeMap';
import { PackingTypeEnum } from 'enums/packingType';
import Quality from 'components/UI/Quality';
import { generateCode } from 'utils/util';
import InfoObservation from 'screens/Products/components/ProductItem/components/InfoObservation';
import imageComum from '../../../../assets/default/image.png';
import ProductOfferPrice from '../ProductOfferPrice';
import OfferTypeBadge from '../OfferTypeBadge';
import * as S from './styles';
import { numberUtils } from '../../../../utils/number';

type ProductOfferProps = {
  productOffer: ProductOffer;
  setSelectedsProducts: React.Dispatch<
    React.SetStateAction<PostBodyProductInCart[]>
  >;
  selectedsProducts?: PostBodyProductInCart[];
  step?: string;
  returnFistStep: React.Dispatch<React.SetStateAction<number>>;
};

const { Option } = Select;

export default function ProductCardListComposition({
  setSelectedsProducts,
  selectedsProducts,
  productOffer,
  step,
  returnFistStep,
}: ProductOfferProps) {
  const {
    offerId,
    offerType,
    defaultImage,
    images,
    hasSavingPrice,
    savingPrice,
    barcode,
    erpCode,
    gtincode,
    quality,
    colors,
    productColorId,
    productQualityId,
    producerId,
    dimension,
    siteId,
    productId,
    packingCost,
    packings,
    layerPrice,
    packagingPrice,
    trolleyPrice,
    startDate,
    endDate,
    observation,
    siteDeliveryPatterns,
    longName,
    shippingFeeFilials,
  } = productOffer;

  const selectedCustomerId = useSelector(
    sessionSelectors.getSelectedCustomerId,
  );
  const { customers } = useSelector(sessionSelectors.getRoot);

  const [arrayImage, setArrayImage] = useState<string[]>([]);
  const [imageDisplay, setImageDisplay] = useState<number>(0);
  const [selectedPackingId, setSelectedPacking] = useState<Packing>(
    (): Packing =>
      packings.find((packing) => packing.type === PackingTypeEnum.RETORNAVEL) ??
      packings.find((packing) => packing.code === '800') ??
      packings[0],
  );
  const cartId = useSelector(cartSelectors.getVolOfferId);
  const billingDate = useSelector(sessionSelectors.getSelectedBillingDate);
  const deliveryDate = useSelector(sessionSelectors.getSelectedDeliveryDate);
  const isAuthenticated = useSelector(sessionSelectors.getAuthenticated);

  const handleImageDisplay = (value: number) => {
    if (value < 0) {
      setImageDisplay(arrayImage.length - 1);
    } else if (value >= arrayImage.length) {
      setImageDisplay(0);
    } else {
      setImageDisplay(value);
    }
  };

  const handleQuantityPacking = (quantity: number) => {
    setSelectedsProducts((oldValue) =>
      oldValue.map((product) => {
        if (product.volOfferId === offerId || product.lkpOfferId === offerId) {
          return {
            ...product,
            quantity,
          };
        }
        return {
          ...product,
        };
      }),
    );
  };

  const getLowPrice = useCallback(() => {
    const priceQuantities = [
      {
        price: packagingPrice,
        quantity: selectedPackingId?.maxAvailablePackingsQuantity,
      },
      {
        price: layerPrice,
        quantity: selectedPackingId?.maxAvailableLayersQuantity,
      },
      {
        price: trolleyPrice,
        quantity: selectedPackingId?.maxAvailableTrolleysQuantity,
      },
    ];

    const ordered = priceQuantities.sort((priceQuantity, nextPriceQuantity) => {
      if (priceQuantity.price < nextPriceQuantity.price) {
        return -1;
      }
      return 1;
    });
    const firstPrice = ordered.find((orderedPrice) => {
      if (orderedPrice.quantity > 0 && orderedPrice.price > 0) {
        return true;
      }
      return false;
    });

    if (firstPrice) {
      return firstPrice.price;
    }
    return ordered[0].price;
  }, [
    trolleyPrice,
    layerPrice,
    packagingPrice,
    selectedPackingId?.maxAvailableLayersQuantity,
    selectedPackingId?.maxAvailablePackingsQuantity,
    selectedPackingId?.maxAvailableTrolleysQuantity,
  ]);

  const handlePackingId = () => {
    let arrayAux: PostBodyProductInCart[] = [];

    selectedsProducts?.forEach((product) => arrayAux.push(product));

    if (
      !arrayAux.find(
        (product) =>
          product.volOfferId === offerId || product.lkpOfferId === offerId,
      )
    ) {
      handleSelectOrRemoveProduct(offerId);
    }

    const productEdit = arrayAux.find(
      (product) =>
        product.volOfferId === offerId || product.lkpOfferId === offerId,
    );

    arrayAux = arrayAux.filter(
      (product) =>
        product.volOfferId !== offerId && product.lkpOfferId !== offerId,
    );

    if (productEdit) {
      arrayAux.push(productEdit);
    }

    setSelectedsProducts(arrayAux);
  };

  const isLkpOffer = useMemo(() => offerType === 1 || offerType === 2, [offerType]);

  const handleSelectOrRemoveProduct = (productIdValue: number) => {
    let arrayAux: PostBodyProductInCart[] = [];
    selectedsProducts?.forEach((product) => arrayAux.push(product));

    if (
      arrayAux.find(
        (product) =>
          product.volOfferId === productIdValue ||
          product.lkpOfferId === productIdValue,
      )
    ) {
      arrayAux = arrayAux.filter(
        (product) =>
          product.volOfferId !== productIdValue &&
          product.lkpOfferId !== productIdValue,
      );
    } else {
      arrayAux.push({
        cartId,
        packingId: selectedPackingId.id,
        maxAvailablePackingsQuantity:
          selectedPackingId.maxAvailablePackingsQuantity,
        volOfferId: !isLkpOffer ? offerId : null,
        lkpOfferId: isLkpOffer ? offerId : null,
        volumeType: 'Packing',
        offerType: offerTypeMap[offerType],
        quantity: 0,
        productColorId,
        productId,
        siteId,
        productQualityId,
        producerId,
        billingDate,
        deliveryDate,
      });
    }
    setSelectedsProducts(arrayAux);

    if (step === 'second-step' && (!arrayAux || arrayAux?.length <= 0)) {
      returnFistStep(0);
    }
  };

  const handleValuePackingSelected = (value: number) => {
    if (value) {
      setSelectedPacking(packings.find((packing) => packing.id === value)!);
      handlePackingId();
    }
  };

  const validQuantity = (event: any) => {
    const newQuantity = event.target.value + parseInt(event.key, 10);

    if (newQuantity > selectedPackingId.maxAvailablePackingsQuantity) {
      return event.preventDefault();
    }
    return numberUtils.onlyNumber(event);
  };

  useEffect(() => {
    if (images && images.length > 0) {
      setArrayImage(images);
    } else {
      setArrayImage([defaultImage || imageComum]);
    }
  }, [images]);

  const handleShippingFee = useCallback(() => {
    const customer = customers.find(c => c.id === selectedCustomerId);

    if(customer?.branch === "CVH_UB"){
      return (
        <Row justify='end'>
          <S.ShippingFeeValue>Valor frete: <b>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL', maximumFractionDigits: 6}).format(shippingFeeFilials[0].productShippingValue)}</b></S.ShippingFeeValue>
        </Row>
      )
    }

    return null;
  }, [customers, selectedCustomerId, shippingFeeFilials])

  return (
    <S.ProductCardListComponent>
      {step === 'first-step' && (
        <S.ProductCardCheck md={1} lg={1} xl={1}>
          <Checkbox
            checked={
              !!selectedsProducts?.find(
                (product) =>
                  product.volOfferId === offerId ||
                  product.lkpOfferId === offerId,
              )
            }
            onChange={() => handleSelectOrRemoveProduct(offerId)}
          />
        </S.ProductCardCheck>
      )}
      <S.ProductCardComponentImage span={2}>
        <div>
          <Button
            type="text"
            onClick={() => handleImageDisplay(imageDisplay - 1)}
            icon={<LeftOutlined />}
            className='arrow-button'
          />
          <ImageGallery
            images={arrayImage}
            currentImage={arrayImage[imageDisplay]}
            maxHeight="150px"
          />
          <Button
            type="text"
            onClick={() => handleImageDisplay(imageDisplay + 1)}
            icon={<RightOutlined />}
            className='arrow-button'
          />
        </div>
        <span className='image-info'>
          Imagem{' '}
          {images.length > 0 && !isLkpOffer ? 'do produtor' : 'ilustrativa'}
        </span>
      </S.ProductCardComponentImage>

      <S.ProductCardComponentDetails span={14}>
        <Row className="row-details-name">
          <Col className="col-details" xs={24}> 
            <S.ProductNameType>
              <div className="name-product">
                <span>{longName}</span>
                <div className="code-product">
                  <div className="number-product">
                    {generateCode(barcode, erpCode)}
                  </div>
                </div>
              </div>
              <div className="remove-display-calendar">
                <OfferTypeBadge
                  siteDeliveryPatterns={siteDeliveryPatterns}
                  offerType={offerType}
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
            </S.ProductNameType>
          </Col>
        </Row>
        <Row className="row-details">
          <Col className="col-details" md={24} lg={18} xl={20}>
            <Row align='top' gutter={16} style={{marginTop: '16px'}}>
              <Col>
              <S.ProductInfo>
                <TrophyOutlined className="icon" />
                  <div className="group-information">
                    <p className="title">Qualidade:</p>
                    <Quality quality={quality} />
                  </div>
              </S.ProductInfo>
              </Col>
              <Col>
                <S.ProductInfo>
                  <ExpandOutlined className="icon" />
                  <div className="group-information">
                    <p className="title">Dimensão/Altura:</p>
                    <span>{dimension}</span>
                  </div>
                </S.ProductInfo>
              </Col>
              <Col>
              <S.ProductInfo>
                  <BgColorsOutlined className="icon" />
                  <div className="group-information">
                    <p className="title">Cores:</p>
                    <span>{colors}</span>
                  </div>
                </S.ProductInfo>
              </Col>
              <Col>
                <S.ProductInfo>
                  <GroupOutlined className="icon" />
                  <div className="group-information">
                    <p className="title">Múltiplo:</p>
                    <span>{selectedPackingId?.multiple}</span>
                  </div>
                </S.ProductInfo>
              </Col>
              <Col span={3}>
                <S.ProductInfo>
                  <GiftOutlined className="icon" />
                  <div className="group-information">
                    <p className="title">Embalagem:</p>
                    {packings && packings.length > 0 ? (
                      <Select
                        onChange={(value) => handleValuePackingSelected(value)}
                        value={selectedPackingId.id}
                        style={{ display: 'flex' }}
                        bordered={false}
                        className='package-select'
                      >
                        {packings &&
                          packings.map((packing: Packing) => (
                            <Option
                              value={packing.id}
                              key={`${offerId}-${packing.id}`}
                            >
                              {packing.code} - {packing.description}
                            </Option>
                          ))}
                      </Select>
                    ) : null}
                  </div>
                </S.ProductInfo>
              </Col>
            </Row>
          </Col>
          <Col
            className="col-details-color"
            style={{ textAlign: 'center' }}
            md={24}
            lg={6}
            xl={4}
          >
            <p>
              Observação:
              <InfoObservation observation={observation}/>
            </p>
          </Col>
        </Row>
      </S.ProductCardComponentDetails>

      <S.ProductCardComponentActions span={7}>
        {handleShippingFee()}
        <Row align='top' gutter={8} className="line-actions">
          <Col>
          <ProductOfferPrice
            className="value-product"
            price={
              selectedPackingId?.additionalCost
                ? packingCost + getLowPrice()
                : getLowPrice()
            }
            hasSavingPrice={hasSavingPrice ?? false}
            savingPrice={savingPrice}
          />
          </Col>
          {isAuthenticated && (
            <Col>
              <S.GroupTypeSaleProduct>
              <Button className="button-type-sale no-border-right focus">
                <span className='title'>Embalagem</span>
                <span className="value">
                  <Monetary
                    symbol
                    value={
                      selectedPackingId?.additionalCost
                        ? packingCost + packagingPrice
                        : packagingPrice
                    }
                  />
                </span>
              </Button>
              <Button
                disabled
                className="button-type-sale no-border-right no-border-left"
              >
                <span className='title'>Camada</span>
                <span className="value">
                  <Monetary
                    symbol
                    value={
                      selectedPackingId?.additionalCost
                        ? packingCost + layerPrice
                        : layerPrice
                    }
                  />
                </span>
              </Button>
              <Button disabled className="button-type-sale no-border-left">
              <span className='title'>Carrinho</span>
                <span className="value">
                  <Monetary
                    symbol
                    value={
                      selectedPackingId?.additionalCost
                        ? packingCost + trolleyPrice
                        : trolleyPrice
                    }
                  />
                </span>
              </Button>
            </S.GroupTypeSaleProduct>
            </Col>
          )}
        </Row>
        <Row className="line-actions-quantity" align='top' gutter={16} justify='end' style={{marginTop: '8px'}}>
          <Col>
          <S.AvailableQuantity>
            <span className="value-available">
              {selectedPackingId.maxAvailablePackingsQuantity} disponíveis
            </span>
            <span className="calc-available">
              {selectedPackingId.maxAvailablePackingsLabel}
            </span>
          </S.AvailableQuantity>
          </Col>

          {step === 'second-step' && (
          <Col className="line-actions-input">
            <Input
              className="input-quantity"
              onKeyDown={(event) => validQuantity(event)}
              onChange={(event) => handleQuantityPacking(+event.target.value)}
              addonAfter="Embalagens"
              style={{ maxWidth: '200px'}}
            />
          </Col>
        )}
        </Row>
      </S.ProductCardComponentActions>
      
      {step === 'second-step' && (
        <S.ProductCardButtonDelete md={1} lg={1} xl={1}>
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleSelectOrRemoveProduct(offerId)}
          />
        </S.ProductCardButtonDelete>
      )}

    </S.ProductCardListComponent>
  );
}
