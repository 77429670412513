import { useCallback, useMemo } from 'react';
import { calculateTotalShipping, roundDecimals } from 'utils/util';
import CartItem from 'model/CartItem';
import moment from 'moment';
import {
  BgColorsOutlined,
  DeleteOutlined,
  ExpandOutlined,
  GiftOutlined,
  TrophyOutlined,
} from '@ant-design/icons';
import Monetary from 'components/UI/Data/Monetary';
import Countdown from 'antd/lib/statistic/Countdown';
import Photo from 'assets/default/image.png';
import { dateUtils } from 'utils/date';
import { Row, Button, Tooltip } from 'antd';
import { Tag } from 'screens/ShoppingCart/styles';
import { generateCode } from 'utils/util';
import ShippingFee from 'components/UI/Data/ShippingFee';
import OfferTypeBadge from '../OfferTypeBadge/index';

import {
  ProductCardCart,
  Frame812,
  ProductCard,
  ProductInfo,
  InfoLeft,
  ProductCardHeader,
  MainInfo,
  ProductName,
  ProductCodes,
  ProductCode,
  BarCode,
  MainInfoSpacing,
  ProductDetail,
  ProductQuality,
  QualityIcon,
  QualityInfo,
  QualityInfoLabel,
  QualityInfoValue,
  QualityInfoSpacing,
  ProductColor,
  ColorIcon,
  ColorInfo,
  ColorInfoLabel,
  ColorInfoValue,
  ColorInfoSpacing,
  ProductPackage,
  PackageIcon,
  PackageInfo,
  PackageInfoLabel,
  PackageInfoValue,
  PackageInfoSpacing,
  ProductSize,
  SizeIcon,
  SizeInfo,
  SizeInfoLabel,
  SizeInfoValue,
  SizeInfoSpacing,
  InfoLeftSpacing,
  InfoCenter,
  ProducedBy,
  ProducedByLabel,
  ButtonText,
  ProductNote,
  ProductDeliveryLabel,
  ProductDeliveryValue,
  ProductDelivery,
  InfoCenterSpacing,
  Frame811,
  InfoRight,
  UnitInfo,
  UnitInfoValue,
  UnitInfoLabel,
  TotalInfo,
  TotalInfoLabel,
  TotalInfoValue,
  PriceInfo,
  UnitPrice,
  UnitPriceLabel,
  UnitPriceInfoLabel,
  UnitPriceInfoValue,
  TotalPrice,
  TotalPriceLabel,
  TotalPriceInfoLabel,
  TotalPriceInfoValue,
  Frame812Spacing,
  ProductPhoto,
  ProductPhotoCol,
  ProductCardCol,
  GroupRemoveAndExpireTime,
  ExpireTimeComponent,
  LabelExpireTime,
  TimerExpireTime,
  DeleteButtonComponent,
  GroupLineItemCart,
  PackingPrice,
  PackingPriceInfoLabel,
  PackingPriceInfoValue,
  PackingPriceLabel,
} from './styles';

type Props = {
  onClickRemoveItem: (id: number) => void;
  cartItem: CartItem;
  isLkp?: boolean;
  onExpiredItem: (id: number) => void;
};

function ProducerProductCardCart({
  onClickRemoveItem,
  cartItem,
  isLkp = false,
  onExpiredItem,
}: Props) {
  const { isExpired, isExpiredInFront } = cartItem;

  const handleExpireTime = (id: number) => {
    onExpiredItem(id);
  };

  function renderVolumeName(param: string, quantity: number) {
    switch (param) {
      case 'L':
        return quantity > 1 ? 'Camadas' : 'Camada';
      case 'T':
        return quantity > 1 ? 'Carrinhos' : 'Carrinho';
      default:
        return quantity > 1 ? 'Embalagens' : 'Embalagem';
    }
  }

  const renderExpirationTime = useMemo(() => {
    if (!cartItem.lkpOfferId) {
      return (
        <ProductDelivery>
          <ProductDeliveryLabel
            className={`${isExpiredInFront || isExpired ? 'expired' : ''}`}
          >
            Previsão de entrega na CVH:
            <ProductDeliveryValue
              className={`${isExpiredInFront || isExpired ? 'expired' : ''}`}
            >
              <span>{cartItem.deliveryDayLabel}</span>
            </ProductDeliveryValue>
          </ProductDeliveryLabel>
        </ProductDelivery>
      );
    }
    return (
      <ProductDelivery>
        <ProductDeliveryLabel
          className={`${isExpiredInFront || isExpired ? 'expired' : ''}`}
        >
          Data do leilão:
          <ProductDeliveryValue
            className={`${isExpiredInFront || isExpired ? 'expired' : ''}`}
          >
            {moment(cartItem.auctionDate).format('[(]ddd[)] DD/MM/YYYY')}
          </ProductDeliveryValue>
        </ProductDeliveryLabel>
      </ProductDelivery>
    );
  }, [cartItem]);

  const calculateConsolidated = useCallback((cartItem: CartItem) => {
    const value = cartItem.unitPriceConsolidated + cartItem.shippingFee;
    return roundDecimals(value, 6);
  }, []);
  return (
    <GroupLineItemCart>
      <ProductCardCart
        className={`borde-box-shadow ${
          (isExpiredInFront || isExpired) && 'expired'
        }`}
      >
        {(isExpiredInFront || isExpired) && (
          <Tooltip title={`${cartItem.status === 'E' ? cartItem.message : ''}`}>
            <Tag
              status={
                cartItem.isExpired
                  ? `${cartItem.status === 'E' ? 'error' : 'success'}`
                  : ''
              }
            >
              {cartItem.isExpired
                ? `${cartItem.status === 'E' ? 'Erro' : 'Produto confirmado'}`
                : 'Confirmando produto'}
            </Tag>
          </Tooltip>
        )}
        <Frame812>
          <Frame812Spacing>
            <ProductCard>
              <ProductPhotoCol>
                {cartItem && cartItem.images && cartItem.images.length > 0 ? (
                  <ProductPhoto preview={false} src={cartItem.images[0]} />
                ) : (
                  <ProductPhoto preview={false} src={Photo} />
                )}
              </ProductPhotoCol>

              <ProductCardCol xs={22} sm={22} md={19} lg={19} xl={21}>
                <ProductInfo>
                  <InfoLeft>
                  <ProductCardHeader>
                        <MainInfo>
                        <ProductCodes>
                              <ProductName
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                {cartItem.description}
                              </ProductName>
                              {cartItem.offerType !== 'V' &&
                              cartItem.offerType !== 'D' ? (
                                <div className="remove-display-calendar">
                                  <OfferTypeBadge
                                    offerType={
                                      cartItem.offerType === 'R' ? 1 : 2
                                    } 
                                  />
                                </div>
                              ) : null}
                            </ProductCodes>
                            <ProductCodes>
                              <ProductCode
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                {generateCode(
                                  cartItem.barcode as string,
                                  cartItem.erpCode,
                                )}
                              </ProductCode>
                              <BarCode
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                {cartItem.gtincode}
                              </BarCode>
                            </ProductCodes>
                        </MainInfo>
                      </ProductCardHeader>
                      <ProductDetail>
                        <ProductQuality>
                          <QualityIcon
                            className={`${
                              isExpiredInFront || isExpired ? 'expired' : ''
                            }`}
                          >
                            <TrophyOutlined />
                          </QualityIcon>
                          <QualityInfo>
                            <QualityInfoSpacing>
                              <QualityInfoLabel
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                Qualidade:
                              </QualityInfoLabel>
                              <QualityInfoValue
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                {cartItem.quality}
                              </QualityInfoValue>
                            </QualityInfoSpacing>
                          </QualityInfo>
                        </ProductQuality>
                        <ProductColor>
                          <ColorIcon
                            className={`${
                              isExpiredInFront || isExpired ? 'expired' : ''
                            }`}
                          >
                            <BgColorsOutlined />
                          </ColorIcon>
                          <ColorInfo>
                            <ColorInfoSpacing>
                              <ColorInfoLabel
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                Cores:
                              </ColorInfoLabel>
                              <ColorInfoValue
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                {cartItem.color}
                              </ColorInfoValue>
                            </ColorInfoSpacing>
                          </ColorInfo>
                        </ProductColor>
                        <ProductSize>
                          <SizeIcon
                            className={`${
                              isExpiredInFront || isExpired ? 'expired' : ''
                            }`}
                          >
                            <ExpandOutlined />
                          </SizeIcon>
                          <SizeInfo>
                            <SizeInfoSpacing>
                              <SizeInfoLabel
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                Dimensão/Altura:
                              </SizeInfoLabel>
                              <SizeInfoValue
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                {cartItem.dimension}
                              </SizeInfoValue>
                            </SizeInfoSpacing>
                          </SizeInfo>
                        </ProductSize>
                        <ProductPackage>
                          <PackageIcon
                            className={`${
                              isExpiredInFront || isExpired ? 'expired' : ''
                            }`}
                          >
                            <GiftOutlined />
                          </PackageIcon>
                          <PackageInfo>
                            <PackageInfoSpacing>
                              <PackageInfoLabel
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                Embalagem:
                              </PackageInfoLabel>
                              <PackageInfoValue
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                {cartItem.packingCode} - {cartItem.packingName}
                              </PackageInfoValue>
                            </PackageInfoSpacing>
                          </PackageInfo>
                        </ProductPackage>
                      </ProductDetail>
                  </InfoLeft>

                  <InfoCenter>
                    <InfoCenterSpacing>
                      <ProducedBy>
                        <ProducedByLabel
                          className={`${
                            isExpiredInFront || isExpired ? 'expired' : ''
                          }`}
                        >
                          <ButtonText>{cartItem.siteName}</ButtonText>
                        </ProducedByLabel>
                      </ProducedBy>

                      <ProductNote>
                        <ProductDeliveryLabel
                          className={`${
                            isExpiredInFront || isExpired ? 'expired' : ''
                          }`}
                        >
                          Observação:
                          <ProductDeliveryValue
                            className={`${
                              isExpiredInFront || isExpired ? 'expired' : ''
                            }`}
                          >
                            {' '}
                            {cartItem?.observation?.length > 40 ? (
                              <Tooltip title={cartItem.observation}>
                                {cartItem?.observation?.length > 40
                                  ? `${cartItem.observation.substring(
                                      0,
                                      40,
                                    )}...`
                                  : cartItem.observation}
                              </Tooltip>
                            ) : (
                              cartItem?.observation
                            )}
                          </ProductDeliveryValue>
                        </ProductDeliveryLabel>
                      </ProductNote>

                      {renderExpirationTime}
                    </InfoCenterSpacing>
                  </InfoCenter>

                  <Frame811>
                    <InfoRight>
                      <PriceInfo>
                        <Row>
                          <UnitInfo>
                            <UnitInfoValue
                              className={`${
                                isExpiredInFront || isExpired ? 'expired' : ''
                              }`}
                            >
                              {cartItem.quantity}{' '}
                              <UnitInfoLabel
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                {renderVolumeName(
                                  cartItem.volumeType,
                                  cartItem.quantity,
                                )}
                              </UnitInfoLabel>
                            </UnitInfoValue>
                          </UnitInfo>

                          <UnitPrice>
                            <UnitPriceLabel
                              className={`${
                                isExpiredInFront || isExpired ? 'expired' : ''
                              }`}
                            >
                              Valor unitário consolidado:
                              <UnitPriceInfoLabel
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                {' '}
                              </UnitPriceInfoLabel>
                              <UnitPriceInfoValue
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                <ShippingFee
                                  value={calculateConsolidated(cartItem)}
                                />
                              </UnitPriceInfoValue>
                            </UnitPriceLabel>
                          </UnitPrice>
                        </Row>

                        <Row>
                          <TotalInfo>
                            <TotalInfoLabel
                              className={`${
                                isExpiredInFront || isExpired ? 'expired' : ''
                              }`}
                            >
                              Total
                              <TotalInfoValue
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                {` ${cartItem.unitQuantity} `}
                              </TotalInfoValue>
                              Unidades
                            </TotalInfoLabel>
                          </TotalInfo>

                          <TotalPrice>
                            <TotalPriceLabel
                              className={`${
                                isExpiredInFront || isExpired ? 'expired' : ''
                              }`}
                            >
                              Total:
                              <PackingPriceInfoLabel
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                {' '}
                                R$
                              </PackingPriceInfoLabel>
                              <TotalPriceInfoValue
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                <Monetary
                                  value={roundDecimals(
                                    cartItem.subTotal +
                                      calculateTotalShipping(cartItem),
                                  )}
                                />
                              </TotalPriceInfoValue>
                            </TotalPriceLabel>
                          </TotalPrice>
                        </Row>

                        {cartItem.shippingFee > 0 && (
                            <Row>
                              <PackingPrice className="left">
                              <PackingPriceLabel
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                Valor frete:
                                <PackingPriceInfoValue
                                  className={`${
                                    isExpiredInFront || isExpired
                                      ? 'expired'
                                      : ''
                                  }`}
                                >
                                  <ShippingFee
                                    value={
                                      cartItem.shippingFee
                                        ? roundDecimals(cartItem.shippingFee, 6)
                                        : 0
                                    }
                                  />
                                </PackingPriceInfoValue>
                              </PackingPriceLabel>
                            </PackingPrice>
                            </Row>
                          )}

                        <Row>
                          <PackingPrice className="left">
                            <PackingPriceLabel
                              className={`${
                                isExpiredInFront || isExpired ? 'expired' : ''
                              }`}
                            >
                              Valor embalagem:
                              <PackingPriceInfoLabel
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                {' '}
                                R$
                              </PackingPriceInfoLabel>
                              <PackingPriceInfoValue
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                <Monetary value={cartItem.packingPrice ?? 0} />
                              </PackingPriceInfoValue>
                            </PackingPriceLabel>
                          </PackingPrice>
                          <PackingPrice>
                            <PackingPriceLabel
                              className={`${
                                isExpiredInFront || isExpired ? 'expired' : ''
                              }`}
                            >
                              Valor unitário:
                              <PackingPriceInfoLabel
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                {' '}
                                R$
                              </PackingPriceInfoLabel>
                              <PackingPriceInfoValue
                                className={`${
                                  isExpiredInFront || isExpired ? 'expired' : ''
                                }`}
                              >
                                <Monetary value={cartItem.unitPrice ?? 0} />
                              </PackingPriceInfoValue>
                            </PackingPriceLabel>
                          </PackingPrice>
                        </Row>
                        {cartItem.voucherAmountLabel &&
                          cartItem.unitPriceConsolidated !==
                            cartItem.unitPrice && (
                            <Row>
                              <PackingPrice className="left">
                                <PackingPriceLabel
                                  className={`${
                                    isExpiredInFront || isExpired
                                      ? 'expired'
                                      : ''
                                  }`}
                                >
                                  {cartItem.unitPriceConsolidated >
                                  cartItem.unitPrice
                                    ? 'Adicional'
                                    : 'Desconto'}
                                  <PackingPriceInfoValue
                                    className={`${
                                      isExpiredInFront || isExpired
                                        ? 'expired'
                                        : ''
                                    }`}
                                  >
                                    {cartItem.voucherAmountLabel}
                                  </PackingPriceInfoValue>
                                </PackingPriceLabel>
                              </PackingPrice>
                            </Row>
                          )}
                      </PriceInfo>
                    </InfoRight>
                  </Frame811>
                </ProductInfo>
              </ProductCardCol>

              <GroupRemoveAndExpireTime>
                {!isLkp && (
                  <ExpireTimeComponent>
                    <LabelExpireTime
                      className={`${
                        isExpiredInFront || isExpired ? 'expired' : ''
                      }`}
                    >
                      Expira
                    </LabelExpireTime>
                    <LabelExpireTime
                      className={`${
                        isExpiredInFront || isExpired ? 'expired' : ''
                      }`}
                    >
                      em
                    </LabelExpireTime>
                    <TimerExpireTime
                      className={`${
                        isExpiredInFront || isExpired ? 'expired' : ''
                      }`}
                    >
                      {!isExpiredInFront ? (
                        <Countdown
                          format="mm:ss"
                          onFinish={() => handleExpireTime(cartItem.id)}
                          value={dateUtils
                            .AddTimeZoneInExpirationDate(
                              cartItem.expirationDate,
                            )
                            .replace('Z', '')}
                        />
                      ) : (
                        '00:00'
                      )}
                    </TimerExpireTime>
                  </ExpireTimeComponent>
                )}
                <DeleteButtonComponent>
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => onClickRemoveItem(cartItem.id)}
                  />
                </DeleteButtonComponent>
              </GroupRemoveAndExpireTime>
            </ProductCard>
          </Frame812Spacing>
        </Frame812>
      </ProductCardCart>
    </GroupLineItemCart>
  );
}

export default ProducerProductCardCart;
