/* eslint-disable no-nested-ternary */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  TrophyOutlined,
  GroupOutlined,
  BgColorsOutlined,
  GiftOutlined,
  ExpandOutlined,
  PushpinOutlined,
} from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { Button, Form, Row, Select, Tooltip } from 'antd';
import ProductOffer from 'model/ProductOffer';
import { sessionActions } from 'store/state/slices';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useGetDeleteCartMutation } from 'services/ecommerceApi';
import { useDispatch, useSelector } from 'react-redux';
import { cartSelectors, sessionSelectors } from 'store/state/selectors';
import Modal from 'antd/lib/modal/Modal';
import DeliveryDate from 'components/UI/DeliveryDate';
import { deliveryDatePattern } from 'services/deliveryDatePattern';
import { PackingTypeEnum } from 'enums/packingType';

import Packing from 'model/Packing';
import Quality from 'components/UI/Quality';
import ProductCardComponentImage from 'components/UI/ProductCardComponentImage.tsx';
import { generateCode } from 'utils/util';
import { PackingTypeSale } from 'enums/packingTypeSale';
import ModalExpiredItemsInCart from '../../../Modals/ExpiredItemsInCart/index';
import OfferTypeBadge from '../OfferTypeBadge';
import ProductOfferPrice from '../ProductOfferPrice';
import imageComum from '../../../../assets/default/image.png';

import ProductActions from '../ProductActions';

import {
  ProductCard,
  ProductCardHeader,
  ProductImage,
  ProductCardFooter,
  MainInfo,
  ProductName,
  InformationVoucher,
  SecondaryInfo,
  ProductCodes,
  ProductCode,
  BarCode,
  ProductCodesSpacing,
  ProducedBy,
  ProducedByValue,
  ProducedBySpacing,
  ProductCardFooterSpacing,
  ProductCardExpanded,
  ProductInfo,
  ProductNote,
  ProductNoteLabel,
  ProductNoteValue,
  ProductDetails,
  ProductDetailLeft,
  ProductQuality,
  QualityIcon,
  QualityInfo,
  QualityInfoLabel,
  QualityInfoSpacing,
  ProductSize,
  SizeIcon,
  SizeInfo,
  SizeInfoLabel,
  SizeInfoValue,
  SizeInfoSpacing,
  ProductMultiple,
  MultipleIcon,
  FormStyled,
  MultipleInfo,
  MultipleLabel,
  MultipleValue,
  MultipleInfoSpacing,
  ProductDetailRight,
  ProductColor,
  ColorIcon,
  ColorInfo,
  ColorInfoLabel,
  ColorInfoValue,
  ColorInfoSpacing,
  ProductPackage,
  CardProduct,
  PackageIcon,
  PackageInfo,
  PackageInfoLabel,
  PackageInfoSpacing,
  ComponentInfoNew,
  ComponentInfoVoucher,
  WarningLabel,
  PinContainer,
} from './styles';
import ShowDetails from '../../../UI/ShowDetails';
import { ProductBuy, ProductBuyHandle } from '../ProductBuy';

const { Option } = Select;
type ProductOfferProps = {
  productOffer: ProductOffer;
  isNewOffer?: boolean;
  setModalIsVisibleComposition: (
    productOfferSelected: ProductOffer,
    packingSelected: Packing,
  ) => void;
  setOfferIdModalComposition: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  setOfferIdInserted: (offerId: number, offertType: number) => void;
};

function ProductOfferCard({
  productOffer,
  isNewOffer,
  setModalIsVisibleComposition,
  setOfferIdModalComposition,
  setOfferIdInserted,
}: ProductOfferProps) {
  const {
    offerId,
    offerType,
    longName,
    defaultImage,
    images,
    hasSavingPrice,
    trolleyPrice,
    voucherReajustmentType,
    minimumUnitQuantityVoucher,
    voucherQuantityType,
    savingPrice,
    siteName,
    barcode,
    erpCode,
    reajustmentAmount,
    packingCost,
    gtincode,
    quality,
    packings,
    colors,
    dimension,
    voucherId,
    layerPrice,
    packagingPrice,
    startDate,
    endDate,
    lkpAuctionDate,
    observation,
    siteDeliveryPatterns,
    isCompositionEnabled,
    unavailableDates,
  } = productOffer;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const sectionRef = useRef<HTMLDivElement>(null);

  const [selectedTypeSale, setSelectedTypeSale] = useState<string>(() => {
    if (packagingPrice) {
      return 'Packing';
    }
    if (layerPrice) {
      return 'Layer';
    }
    return 'Trolley';
  });
  const [form] = Form.useForm();
  const [arrayImage, setArrayImage] = useState<string[]>([]);
  const [fastBuy, setFastBuy] = useState(false);

  const [showModalExpiredItemsInCart, setShowModalExpiredItemsInCart] =
    useState<boolean>(false);
  const [textInputTypeSale, setTextInputTypeSale] = useState<string>(() => {
    if (packagingPrice) {
      return 'Embalagem(s)';
    }
    if (layerPrice) {
      return 'Camada(s)';
    }
    return 'Carrinho(s)';
  });
  const [displayModalInformation, setDisplayModalInformation] =
    useState<boolean>(false);

  const [
    displayModalInformationComposition,
    setDisplayModalInformationComposition,
  ] = useState<boolean>(false);
  const [imageDisplay, setImageDisplay] = useState<number>(0);
  const [packingSelected, setPackingSelected] = useState<Packing>(
    {} as Packing,
  );
  const [cardPinned, setCardPinned] = useState(false);

  const [getDeleteCart] = useGetDeleteCartMutation();

  const lkpOfferId = useSelector(cartSelectors.getLkpOfferId);
  const volOfferId = useSelector(cartSelectors.getVolOfferId);
  const billingDate = useSelector(sessionSelectors.getSelectedBillingDate);
  const isProducerAuthenticated = useSelector(
    sessionSelectors.isProducerAuthenticated,
  );
  const deliveryDate = useSelector(sessionSelectors.getSelectedDeliveryDate);
  const isAuthenticated = useSelector(sessionSelectors.getAuthenticated);
  const producerUrl = useSelector(sessionSelectors.getProducerUrl);

  const handleImageDisplay = (value: number) => {
    if (value < 0) {
      setImageDisplay(arrayImage.length - 1);
    } else if (value >= arrayImage.length) {
      setImageDisplay(0);
    } else {
      setImageDisplay(value);
    }
  };

  const handleClickButtonGoToCart = () => {
    navigate('/veiling/shopping-cart');
    setShowModalExpiredItemsInCart(false);
  };

  const handleClickButtonDeleteCart = () => {
    const isLkpOffer = offerType === 1 || offerType === 2;
    const cartId = isLkpOffer ? lkpOfferId : volOfferId;
    getDeleteCart({ cartId });
    setShowModalExpiredItemsInCart(false);
  };

  const getLabel = useCallback(() => {
    if (selectedTypeSale === 'Packing') {
      return packingSelected?.maxAvailablePackingsLabel || '';
    }
    if (selectedTypeSale === 'Layer') {
      return packingSelected?.maxAvailableLayersLabel || '';
    }
    return packingSelected?.maxAvailableTrolleysLabel || '';
  }, [
    selectedTypeSale,
    packingSelected.maxAvailableTrolleysLabel,
    packingSelected.maxAvailableLayersLabel,
    packingSelected.maxAvailablePackingsLabel,
  ]);

  const getMaxAvailable = useMemo(() => {
    if (selectedTypeSale === 'Packing') {
      return {
        label: packingSelected?.maxAvailablePackingsLabel || '',
        max: packingSelected?.maxAvailablePackingsQuantity || 0,
        min: packingSelected?.unitsPerPackage,
      };
    }
    if (selectedTypeSale === 'Layer') {
      return {
        label: packingSelected?.maxAvailableLayersLabel || '',
        max: packingSelected?.maxAvailableLayersQuantity || 0,
        min:
          packingSelected?.packagingByLayer * packingSelected?.unitsPerPackage,
      };
    }
    return {
      label: packingSelected?.maxAvailableTrolleysLabel || '',
      max: packingSelected?.maxAvailableTrolleysQuantity || 0,
      min:
        packingSelected?.packagingByLayer *
        packingSelected?.layersByTrolley *
        packingSelected?.unitsPerPackage,
    };
  }, [packingSelected, selectedTypeSale]);

  useEffect(() => {
    const packing =
      packings.find((packing) => packing.type === PackingTypeEnum.RETORNAVEL) ??
      packings.find((packing) => packing.code === '800') ??
      packings[0];

    setPackingSelected(packing);
  }, [packings]);

  useEffect(() => {
    const quantityPackingValue = form.getFieldValue(['quantityPacking']);
    if (quantityPackingValue) {
      form.validateFields(['quantityPacking']);
    }
  }, [getMaxAvailable]);

  useEffect(() => {
    if (packings && packings.length > 0) {
      form.setFieldsValue({
        packingSelectedId:
          packings.find(
            (packing) => packing.type === PackingTypeEnum.RETORNAVEL,
          )?.id ??
          packings.find((packing) => packing.code === '800')?.id ??
          packings[0]?.id,
      });
    }
  }, [form, packings, productOffer]);

  const clickCloseModalInformation = () => {
    setDisplayModalInformation(false);
    setShowDatePickerDelivery(false);
  };

  const clickCloseModalInformationComposition = () => {
    setDisplayModalInformationComposition(false);
  };

  const handleVisibleModalComposition = () => {
    if (deliveryDate && billingDate) {
      setModalIsVisibleComposition(productOffer, packingSelected);
      setOfferIdModalComposition(offerId);
    } else {
      setDisplayModalInformationComposition(true);
    }
  };

  const handleTypeSale = (value: string) => {
    if (value === 'Packing') {
      setSelectedTypeSale('Packing');
      setTextInputTypeSale('Embalagem(s)');
    } else if (value === 'Layer') {
      setSelectedTypeSale('Layer');
      setTextInputTypeSale('Camada(s)');
    } else if (value === 'Trolley') {
      setSelectedTypeSale('Trolley');
      setTextInputTypeSale('Carrinho(s)');
    }
  };

  const [isVisibleSelectPackage, setIsVisibleSelectPackage] =
    useState<boolean>(false);

  const handleOnDropdownVisibleChange = (value: boolean) => {
    setIsVisibleSelectPackage(value);
  };

  const isLkpOffer = useMemo(
    () => offerType === 1 || offerType === 2,
    [offerType],
  );

  useEffect(() => {
    if (images && images.length > 0) {
      setArrayImage(images);
    } else {
      setArrayImage([defaultImage || imageComum]);
    }
  }, [images]);

  const handleOnSelect = (id: number) => {
    setPackingSelected(packings.find((packing) => packing.id === id)!);
    onMouseEnterEvent();
  };

  const onMouseEnterEvent = () => {
    sectionRef.current?.children[0].classList.add('hover');
  };
  const onMouseLeaveEvent = () => {
    sectionRef.current?.children[0].classList.remove('hover');
  };

  useEffect(() => {
    sectionRef.current?.addEventListener('mouseenter', onMouseEnterEvent);
    sectionRef.current?.addEventListener('mouseleave', onMouseLeaveEvent);
    return () => {
      sectionRef.current?.removeEventListener('mouseenter', onMouseEnterEvent);
      sectionRef.current?.removeEventListener('mouseleave', onMouseLeaveEvent);
    };
  }, [sectionRef]);

  // Modal para data
  const [deliveryDateValue, setDeliveryDateValue] = useState<Moment | null>(
    null,
  );
  const [showDatePickerDelivery, setShowDatePickerDelivery] =
    useState<boolean>(true);
  const [showDatePickerBilling, setShowDatePickerBilling] =
    useState<boolean>(false);
  const [billingDateValue, setBillingDateValue] = useState<Moment | null>(null);

  const disableDatesDelivery = (value: Moment) => {
    const calendarValue = moment(value).set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 0,
    });
    const dateNow = moment().set({
      hour: 0,
      minute: 0,
      second: 1,
      millisecond: 0,
    });
    const endDateFormatted = moment(lkpAuctionDate).set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 0,
    });
    const deliveryDays = siteDeliveryPatterns.map(
      (date) => date.deliveryDayNumber,
    );
    const deliveryDate = deliveryDatePattern(
      siteDeliveryPatterns,
    ).getDeliveryDateByDatePatterns(dateNow.toDate().getDay());
    return (
      calendarValue.isBefore(deliveryDate) ||
      value.isAfter(endDateFormatted) ||
      !deliveryDays.includes(calendarValue.day())
    );
  };

  const disableDatesBilling = (value: Moment) => {
    if (deliveryDateValue !== null) {
      const initalDay = moment(deliveryDateValue);
      const lastDay = moment(deliveryDateValue).add(2, 'days');

      return value.isAfter(lastDay) || value.isBefore(initalDay);
    }
    return false;
  };

  const handleOnOpenChangeDelivery = (event: boolean) => {
    setShowDatePickerDelivery(event);

    if (deliveryDateValue !== null) {
      setShowDatePickerBilling(true);
    }
  };

  const handleOnOpenChangeBilling = (event: boolean) => {
    setShowDatePickerBilling(event);
  };

  const handleOnChangeDelivery = (value: Moment | null) => {
    setBillingDateValue(null);
    setDeliveryDateValue(value);

    if (value) {
      setShowDatePickerBilling(true);
    }
  };

  const handleOnChangeBilling = (value: Moment | null) => {
    setBillingDateValue(value);
  };

  const handleOnConfirmationDeliveryDate = () => {
    dispatch(
      sessionActions.setDates({
        billingDate:
          billingDateValue?.set('hour', 0).format('YYYY-MM-DD') ?? '',
        deliveryDate:
          deliveryDateValue?.set('hour', 0).format('YYYY-MM-DD') ?? '',
      }),
    );
    clickCloseModalInformation();
    form.submit();
  };

  const handleOnConfirmationDeliveryDateComposition = () => {
    dispatch(
      sessionActions.setDates({
        billingDate:
          billingDateValue?.set('hour', 0).format('YYYY-MM-DD') ?? '',
        deliveryDate:
          deliveryDateValue?.set('hour', 0).format('YYYY-MM-DD') ?? '',
      }),
    );
    clickCloseModalInformationComposition();
    setModalIsVisibleComposition(productOffer, packingSelected);
    setOfferIdModalComposition(offerId);
  };

  const getLowPrice = useMemo(() => {
    const priceQuantities = [
      {
        price: packagingPrice,
        quantity: packingSelected.maxAvailablePackingsQuantity,
      },
      {
        price: layerPrice,
        quantity: packingSelected?.maxAvailableLayersQuantity,
      },
      {
        price: trolleyPrice,
        quantity: packingSelected.maxAvailableTrolleysQuantity,
      },
    ];

    const ordered = priceQuantities.sort((priceQuantity, nextPriceQuantity) => {
      if (priceQuantity.price < nextPriceQuantity.price) {
        return -1;
      }
      return 1;
    });
    const firstPrice = ordered.find((orderedPrice) => {
      if (orderedPrice.quantity > 0 && orderedPrice.price > 0) {
        return true;
      }
      return false;
    });

    if (firstPrice) {
      return firstPrice.price;
    }
    return ordered[0].price;
  }, [
    trolleyPrice,
    layerPrice,
    packagingPrice,
    packingSelected?.maxAvailableLayersQuantity,
    packingSelected.maxAvailablePackingsQuantity,
    packingSelected.maxAvailableTrolleysQuantity,
  ]);
  const handleClickPinned = useCallback(() => {
    setCardPinned((value) => !value);
  }, []);
  const handleClickFastBuy = useCallback(() => {
    setFastBuy(true);
    form.submit();
  }, []);
  const productBuyRef = useRef<ProductBuyHandle>(null);
  const handleSubmit = (formValues: any) => {
    productBuyRef.current?.verifyXpedAndBuyFunc(formValues);
  };
  return (
    <CardProduct ref={sectionRef}>
      <ProductCard className="product-cards" pinned={cardPinned ? 1 : 0}>
        {isNewOffer && (
          <ComponentInfoNew className={voucherId ? `voucher-active` : ''}>
            Novo
          </ComponentInfoNew>
        )}
        {voucherId && (
          <ComponentInfoVoucher>
            {reajustmentAmount < 0 ? 'Desconto ' : 'Adicional '}
            <span className="valeu">
              {voucherReajustmentType === 'P'
                ? `${Math.abs(reajustmentAmount)}%`
                : `R$${Math.abs(reajustmentAmount)}`}
            </span>
          </ComponentInfoVoucher>
        )}
        <FormStyled form={form} onFinish={handleSubmit}>
          {isAuthenticated && (
            <ProductCardHeader>
              <OfferTypeBadge
                siteDeliveryPatterns={siteDeliveryPatterns}
                offerType={offerType}
                startDate={startDate}
                endDate={endDate}
              />
              {!isMobile && (
                <PinContainer>
                  <Tooltip title="Manter informações visíveis">
                    <Button
                      type="text"
                      onClick={handleClickPinned}
                      shape="circle"
                      icon={
                        <PushpinOutlined
                          style={{
                            fontSize: '14px',
                            color: cardPinned ? '#faad14' : 'initial',
                          }}
                        />
                      }
                    />
                  </Tooltip>
                </PinContainer>
              )}
            </ProductCardHeader>
          )}
          <ProductImage>
            <ProductCardComponentImage
              arrayImage={arrayImage}
              images={images}
              isLkpOffer={isLkpOffer}
              xl={24}
              maxHeight="150px"
            />
          </ProductImage>
          <ProductCardFooter>
            <ProductCardFooterSpacing>
              <MainInfo>
                <Tooltip title={longName}>
                  <ProductName>
                    {longName.slice(0, 20) +
                      (longName.length > 20 ? '...' : '')}
                  </ProductName>
                </Tooltip>
                <ProductOfferPrice
                  price={
                    packingSelected?.additionalCost
                      ? getLowPrice + packingCost
                      : getLowPrice
                  }
                  hasSavingPrice={hasSavingPrice ?? false}
                  savingPrice={savingPrice}
                />
              </MainInfo>
              <SecondaryInfo>
                <ProductCodes>
                  <ProductCodesSpacing>
                    <ProductCode>{gtincode}</ProductCode>
                    <BarCode>{generateCode(barcode, erpCode)}</BarCode>
                  </ProductCodesSpacing>
                </ProductCodes>
                <ProducedBy>
                  <ProducedBySpacing>
                    <ProducedByValue>{siteName}</ProducedByValue>
                  </ProducedBySpacing>
                </ProducedBy>
              </SecondaryInfo>
            </ProductCardFooterSpacing>
          </ProductCardFooter>
          {isAuthenticated && (
            <ProductCardExpanded
              className={`cardExpanded ${
                isVisibleSelectPackage ? 'not-display-none' : ''
              }`}
            >
              <ProductInfo>
                <ProductNote>
                  <ProductNoteLabel>Observação:</ProductNoteLabel>
                  <ProductNoteValue>
                    {' '}
                    {observation?.length > 30 ? (
                      <Tooltip title={observation}>
                        {observation?.length > 30
                          ? `${observation.substring(0, 30)}...`
                          : observation}
                      </Tooltip>
                    ) : (
                      observation
                    )}
                  </ProductNoteValue>
                </ProductNote>
                <ProductDetails>
                  <ProductDetailLeft>
                    <ProductQuality>
                      <QualityIcon>
                        <TrophyOutlined
                          style={{ fontSize: '16px', color: '#f88f1f' }}
                        />
                      </QualityIcon>
                      <QualityInfo>
                        <QualityInfoSpacing>
                          <QualityInfoLabel>Qualidade:</QualityInfoLabel>
                          <Quality quality={quality} />
                        </QualityInfoSpacing>
                      </QualityInfo>
                    </ProductQuality>
                    <ProductSize>
                      <SizeIcon>
                        <ExpandOutlined
                          style={{ fontSize: '16px', color: '#f88f1f' }}
                        />
                      </SizeIcon>
                      <SizeInfo>
                        <SizeInfoSpacing>
                          <SizeInfoLabel>Dimensão/Altura:</SizeInfoLabel>
                          <SizeInfoValue>{dimension}</SizeInfoValue>
                        </SizeInfoSpacing>
                      </SizeInfo>
                    </ProductSize>
                  </ProductDetailLeft>
                  <ProductDetailRight>
                    <ProductColor>
                      <ColorIcon>
                        <BgColorsOutlined
                          style={{ fontSize: '16px', color: '#f88f1f' }}
                        />
                      </ColorIcon>
                      <ColorInfo>
                        <ColorInfoSpacing>
                          <ColorInfoLabel>Cores:</ColorInfoLabel>
                          <ColorInfoValue>{colors}</ColorInfoValue>
                        </ColorInfoSpacing>
                      </ColorInfo>
                    </ProductColor>
                    <ProductMultiple>
                      <MultipleIcon>
                        <GroupOutlined
                          style={{ fontSize: '16px', color: '#f88f1f' }}
                        />
                      </MultipleIcon>
                      <MultipleInfo>
                        <MultipleInfoSpacing>
                          <MultipleLabel>Múltiplo:</MultipleLabel>
                          <MultipleValue>
                            {packingSelected?.multiple ?? 0}
                          </MultipleValue>
                        </MultipleInfoSpacing>
                      </MultipleInfo>
                    </ProductMultiple>
                  </ProductDetailRight>
                  <ProductPackage>
                    <Row style={{ marginLeft: '-4px' }}>
                      <PackageIcon>
                        <GiftOutlined
                          style={{
                            fontSize: '16px',
                            color: '#f88f1f',
                            paddingTop: '8px',
                          }}
                        />
                      </PackageIcon>
                      <PackageInfo>
                        <PackageInfoSpacing id="packingSpace">
                          <PackageInfoLabel>Embalagem:</PackageInfoLabel>
                          {packings!.length && (
                            <Form.Item name="packingSelectedId">
                              <Select
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                style={{ display: 'flex', minWidth: '170px' }}
                                bordered={false}
                                onDropdownVisibleChange={
                                  handleOnDropdownVisibleChange
                                }
                                onSelect={handleOnSelect}
                              >
                                {packings.map((packing: Packing) => (
                                  <Option
                                    value={packing.id}
                                    key={`${offerId}-${packing.id}`}
                                  >
                                    {packing.code} - {packing.abbrev}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          )}
                        </PackageInfoSpacing>
                      </PackageInfo>
                    </Row>
                  </ProductPackage>
                </ProductDetails>
              </ProductInfo>
              {voucherId && (
                <InformationVoucher>{`*Para aplicação do ${
                  reajustmentAmount < 0 ? 'desconto' : 'adicional'
                } a quantidade mínima deve ser de ${minimumUnitQuantityVoucher} ${
                  voucherQuantityType === 'P'
                    ? minimumUnitQuantityVoucher > 1
                      ? 'embalagens'
                      : 'embalagem'
                    : voucherQuantityType === 'L'
                    ? minimumUnitQuantityVoucher > 1
                      ? 'camadas'
                      : 'camada'
                    : minimumUnitQuantityVoucher > 1
                    ? 'carrinhos'
                    : 'carrinho'
                }. O valor ${
                  reajustmentAmount < 0 ? 'do desconto' : 'adicional'
                } será aplicado sobre o preço do produto`}</InformationVoucher>
              )}
              {isAuthenticated && (
                <ProductActions
                  isProducerAuthenticated={isProducerAuthenticated}
                  packagingPrice={packagingPrice}
                  packingSelected={packingSelected}
                  handleTypeSale={handleTypeSale}
                  selectedTypeSale={selectedTypeSale}
                  packingCost={packingCost}
                  layerPrice={layerPrice}
                  trolleyPrice={trolleyPrice}
                  getMaxAvailable={getMaxAvailable}
                  getLabel={getLabel}
                  textInputTypeSale={textInputTypeSale}
                  isCompositionEnabled={isCompositionEnabled}
                  handleVisibleModalComposition={handleVisibleModalComposition}
                  submit={form.submit}
                  handleClickFastBuy={handleClickFastBuy}
                  isLkpOffer={isLkpOffer}
                />
              )}
              <ShowDetails
                producerUrl={producerUrl}
                offerId={offerId}
                offerType={offerType}
                selectedTypeSale={selectedTypeSale as PackingTypeSale}
                packingSelectedId={packingSelected.id}
              />
            </ProductCardExpanded>
          )}

          <Modal
            closable
            onCancel={clickCloseModalInformationComposition}
            visible={displayModalInformationComposition}
            width="414px"
            footer={null}
          >
            <WarningLabel>
              <b>Atenção!</b> É necessário que selecione uma data para entrega e
              faturamento.
            </WarningLabel>
            <DeliveryDate
              initialDatePicker={{
                disabled: false,
                disabledDate: disableDatesDelivery,
                value: deliveryDateValue,
                open:
                  showDatePickerDelivery && displayModalInformationComposition,
                onOpenChange: handleOnOpenChangeDelivery,
                onChange: handleOnChangeDelivery,
              }}
              endDatePicker={{
                disabled: deliveryDateValue === null,
                disabledDate: disableDatesBilling,
                value: billingDateValue,
                open:
                  showDatePickerBilling &&
                  displayModalInformationComposition &&
                  !showDatePickerDelivery,
                onOpenChange: handleOnOpenChangeBilling,
                onChange: handleOnChangeBilling,
              }}
              onConfirmation={handleOnConfirmationDeliveryDateComposition}
              producerUnavailableDates={unavailableDates}
            />
          </Modal>
          <Modal
            closable
            onCancel={clickCloseModalInformation}
            visible={displayModalInformation}
            width="414px"
            footer={null}
          >
            <WarningLabel>
              <b>Atenção!</b> É necessário que selecione uma data para entrega e
              faturamento.
            </WarningLabel>
            {displayModalInformation && (
              <DeliveryDate
                initialDatePicker={{
                  disabled: false,
                  disabledDate: disableDatesDelivery,
                  value: deliveryDateValue,
                  open: showDatePickerDelivery && displayModalInformation,
                  onOpenChange: handleOnOpenChangeDelivery,
                  onChange: handleOnChangeDelivery,
                }}
                endDatePicker={{
                  disabled: deliveryDateValue === null,
                  disabledDate: disableDatesBilling,
                  value: billingDateValue,
                  open:
                    showDatePickerBilling &&
                    displayModalInformation &&
                    !showDatePickerDelivery,
                  onOpenChange: handleOnOpenChangeBilling,
                  onChange: handleOnChangeBilling,
                }}
                onConfirmation={handleOnConfirmationDeliveryDate}
                producerUnavailableDates={unavailableDates}
              />
            )}
          </Modal>
          <ModalExpiredItemsInCart
            modalIsVisible={showModalExpiredItemsInCart}
            actionButtonCancel={handleClickButtonGoToCart}
            actionButtonOk={handleClickButtonDeleteCart}
          />
        </FormStyled>
      </ProductCard>
      <ProductBuy
        ref={productBuyRef}
        billingDateValue={billingDateValue}
        deliveryDateValue={deliveryDateValue}
        fastBuy={fastBuy}
        productOffer={productOffer}
        selectedTypeSale={selectedTypeSale}
        onFinish={() => setFastBuy(false)}
        onResetFields={form.resetFields}
        onSetDisplayModalInformation={setDisplayModalInformation}
      />
    </CardProduct>
  );
}

export default ProductOfferCard;
