import { useCallback, useMemo, useRef, useState } from 'react';
import {
  BgColorsOutlined,
  ExpandOutlined,
  GiftOutlined,
  GroupOutlined,
  PictureOutlined,
  TrophyOutlined,
} from '@ant-design/icons';
import { Form, Select, Tooltip } from 'antd';
import moment from 'moment';

import ProductOfferPrice from 'components/BusinessLogic/Product/ProductOfferPrice';
import ProductActions from 'components/BusinessLogic/Product/ProductActions/index';
import Packing from 'model/Packing';
import ProductOffer from 'model/ProductOffer';

import Badge from '../Badge';
import ImageGallery from '../ImageGallery';

import {
  Container,
  ContainerFixed,
  ImageContainer,
  ButtonImage,
  IconContainer,
  ProductInfoContainer,
  ColContainer,
  InfoRow,
  PrimaryInfo,
  InformationIconContainer,
  InformationContainer,
  ProductName,
  BarCode,
  SecondaryInfo,
  Information,
  InformationLabel,
  ProducerValue,
  BadgeContainer,
  UnitValueContainer,
  HideContainer,
  ContainerComplementary,
  InfoComplementary,
  Actions,
  InfoValueContainerGroup,
  InfoValueContainer,
  InfoValue,
  Label,
  Value,
  ComponentInfoNew,
  ComponentInfoVoucher,
  ContainerFloat,
  InformationVoucher,
} from './styles';
import PopoverCalendar from '../PopoverCalendarDates';
import Quality from '../Quality';
import { generateCode } from 'utils/util';

interface Props {
  productOffer: ProductOffer;
  packingSelected: Packing;
  images: string[];
  getLowPrice: number;
  handleOnSelect: (id: number) => void;
  isAuthenticated: boolean;
  isProducerAuthenticated: boolean;
  runInMobile: boolean;
  packagingPrice: number;
  handleTypeSale: (value: string) => void;
  selectedTypeSale: string;
  layerPrice: number;
  trolleyPrice: number;
  getMaxAvailable: {
    label: string;
    max: number;
    min: number;
  };
  getLabel: () => string;
  textInputTypeSale: string;
  handleVisibleModalComposition: () => void;
  submit: () => void;
  infoVoucherLabel: JSX.Element;
  infoVoucher: string;
  handleClickFastBuy: () => void;
}

const { Option } = Select;

const offerTypeColor: Record<
  number,
  { backgroundColor: string; text: string }
> = {
  0: {
    backgroundColor: '#FAAD14',
    text: 'no sítio',
  },
  1: {
    backgroundColor: '#52C41A',
    text: 'recepcionado (LKP)',
  },
  2: {
    backgroundColor: '#13C2C2',
    text: 'no sítio(LKP)',
  },
};

function ProductListMobile({
  productOffer,
  packingSelected,
  images,
  handleOnSelect,
  runInMobile,
  getLowPrice,
  isAuthenticated,
  packagingPrice,
  handleTypeSale,
  selectedTypeSale,
  layerPrice,
  trolleyPrice,
  isProducerAuthenticated,
  getMaxAvailable,
  getLabel,
  textInputTypeSale,
  handleVisibleModalComposition,
  submit,
  infoVoucherLabel,
  infoVoucher,
  handleClickFastBuy,
}: Props) {
  
  const {
    longName,
    gtincode,
    quality,
    hasSavingPrice,
    savingPrice,
    packingCost,
    siteName,
    offerType,
    observation,
    dimension,
    colors,
    packings,
    offerId,
    isCompositionEnabled,
    barcode,
    erpCode,
    isNewOffer,
    voucherId,
    siteDeliveryPatterns,
    startDate,
    endDate,
    isLkpOffer,
  } = productOffer;

  const openImage = useRef(() => null);

  const [showComplement, setShowComplement] = useState(false);

  const endDateFormatted = useMemo(
    () => moment(endDate).format('DD/MM'),
    [endDate],
  );

  const toggleShowComplement = useCallback(() => {
    setShowComplement((value) => !value);
  }, []);

  return (
    <>
      <Container>
        <ContainerFixed
          onClick={() => toggleShowComplement()}
          isShowingComplement={showComplement ? 1 : 0}
        >
          <ContainerFloat>
            {isNewOffer && <ComponentInfoNew>Novo</ComponentInfoNew>}
            {voucherId && (
              <ComponentInfoVoucher>{infoVoucherLabel}</ComponentInfoVoucher>
            )}
          </ContainerFloat>

          <ImageContainer isShowingComplement={showComplement ? 1 : 0}>
            <ButtonImage onClick={openImage.current}>
              <IconContainer>
                <PictureOutlined />
              </IconContainer>
            </ButtonImage>
          </ImageContainer>
          <ProductInfoContainer>
            <ColContainer>
              <InfoRow>
                <PrimaryInfo>
                  <InformationIconContainer isLkpOffer={isLkpOffer ? 1 : 0}>
                    {isLkpOffer ? (
                      <p>{endDateFormatted}</p>
                    ) : (
                      <PopoverCalendar
                        startDate={startDate}
                        endDate={endDate}
                        siteDeliveryPatterns={siteDeliveryPatterns}
                      />
                    )}
                  </InformationIconContainer>
                  <InformationContainer>
                    <Tooltip title={longName}>
                      <ProductName>
                        {longName.slice(0, 23) +
                          (longName.length > 23 ? '...' : '')}
                      </ProductName>
                    </Tooltip>
                    <BarCode>
                      <span>{generateCode(barcode, erpCode)}</span>
                      <span>{gtincode}</span>
                    </BarCode>
                  </InformationContainer>
                </PrimaryInfo>
              </InfoRow>
              <InfoRow>
                <PrimaryInfo>
                  <InformationIconContainer>
                    <TrophyOutlined />
                  </InformationIconContainer>
                  <InformationContainer>
                    <Information>
                      <InformationLabel>Qualidade:</InformationLabel>
                      <Quality quality={quality} />
                    </Information>
                  </InformationContainer>
                </PrimaryInfo>
                <SecondaryInfo>
                  <Information inline={1}>
                    <ProducerValue>{siteName}</ProducerValue>
                  </Information>
                </SecondaryInfo>
              </InfoRow>
            </ColContainer>
            <ColContainer>
              <BadgeContainer>
                <Badge
                  backgroundColor={offerTypeColor[offerType].backgroundColor}
                  text={offerTypeColor[offerType].text}
                />
              </BadgeContainer>
              <UnitValueContainer>
                <ProductOfferPrice
                  className="value-product"
                  price={
                    packingSelected?.additionalCost
                      ? packingCost + getLowPrice
                      : getLowPrice
                  }
                  hasSavingPrice={hasSavingPrice ?? false}
                  savingPrice={savingPrice}
                />
              </UnitValueContainer>
            </ColContainer>
          </ProductInfoContainer>
        </ContainerFixed>
        <ContainerComplementary show={showComplement ? 1 : 0}>
          <InfoComplementary>
            {observation && (
              <InfoValueContainerGroup>
                <InfoValueContainer>
                  <InfoValue>
                    <Label>Observação:</Label>
                    <Tooltip title={observation}>
                      <Value>
                        {observation?.length > 40
                          ? `${observation.substring(0, 40)}...`
                          : observation}
                      </Value>
                    </Tooltip>
                  </InfoValue>
                </InfoValueContainer>
              </InfoValueContainerGroup>
            )}
            <InfoValueContainerGroup>
              <InfoValueContainer>
                <InformationIconContainer>
                  <ExpandOutlined />
                </InformationIconContainer>
                <InfoValue>
                  <Label>Dimensão:</Label>
                  <Value>{dimension}</Value>
                </InfoValue>
              </InfoValueContainer>
              <InfoValueContainer>
                <InformationIconContainer>
                  <GroupOutlined />
                </InformationIconContainer>
                <InfoValue>
                  <Label>Múltiplo:</Label>
                  <Value>{packingSelected?.multiple}</Value>
                </InfoValue>
              </InfoValueContainer>
              <InfoValueContainer>
                <InformationIconContainer>
                  <BgColorsOutlined />
                </InformationIconContainer>
                <InfoValue>
                  <Label>Cores:</Label>
                  <Value>{colors}</Value>
                </InfoValue>
              </InfoValueContainer>
            </InfoValueContainerGroup>

            <InfoValueContainerGroup>
              <InfoValueContainer>
                <InformationIconContainer>
                  <GiftOutlined />
                </InformationIconContainer>
                <InfoValue>
                  <Label>Embalagem:</Label>
                  <Value>
                    <Form.Item
                      name="packingSelectedId"
                      style={{ marginBottom: 4 }}
                    >
                      <Select
                        style={{ display: 'flex' }}
                        className="select-packing"
                        bordered={false}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        onSelect={handleOnSelect}
                      >
                        {packings &&
                          packings.map((packing: Packing) => (
                            <Option
                              value={packing.id}
                              key={`${offerId}-${packing.id}`}
                            >
                              {packing.code} - {packing.abbrev}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Value>
                </InfoValue>
              </InfoValueContainer>
            </InfoValueContainerGroup>

            <InfoValueContainerGroup>
              {voucherId && (
                <InformationVoucher>{infoVoucher}</InformationVoucher>
              )}
            </InfoValueContainerGroup>
          </InfoComplementary>
          <Actions>
            {isAuthenticated && (
              <ProductActions
                runInMobile={runInMobile}
                isProducerAuthenticated={isProducerAuthenticated}
                packagingPrice={packagingPrice}
                packingSelected={packingSelected}
                handleTypeSale={handleTypeSale}
                selectedTypeSale={selectedTypeSale}
                packingCost={packingCost}
                layerPrice={layerPrice}
                trolleyPrice={trolleyPrice}
                getMaxAvailable={getMaxAvailable}
                getLabel={getLabel}
                textInputTypeSale={textInputTypeSale}
                isCompositionEnabled={isCompositionEnabled}
                isLkpOffer={isLkpOffer}
                handleVisibleModalComposition={handleVisibleModalComposition}
                submit={submit}
                handleClickFastBuy={isLkpOffer ? handleClickFastBuy : undefined}
              />
            )}
          </Actions>
        </ContainerComplementary>
      </Container>

      <HideContainer>
        {images.length > 0 && (
          <ImageGallery
            images={images}
            currentImage={images[0]}
            alt="imagem do produto"
            maxHeight="150px"
            open={openImage}
          />
        )}
      </HideContainer>
    </>
  );
}

export default ProductListMobile;
