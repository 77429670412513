/* eslint-disable consistent-return */
import { DateTypeFilterEnum } from 'enums/dateTypeFilter';
import { SaleTypeFilterEnum } from 'enums/saleTypeFilter';
import CartItem from 'model/CartItem';
import FormFiltersFields from 'model/FormFiltersFields';
import Packing from 'model/Packing';
import { OrdersFiltersParam } from 'model/types';

export function notSpecialCharacters(evt: any) {
  const lettersPattern = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;

  const keysAccepteds = [
    'Delete',
    'Backspace',
    'ArrowLeft',
    'ArrowRight',
    'Tab',
  ];

  if (
    !lettersPattern.test(evt.key) &&
    !keysAccepteds.includes(evt.key) &&
    evt.code !== 'Space'
  ) {
    return evt.preventDefault();
  }
}

export const redirectDirectedOffers = (producerUrl: string) => {
  window.location.href = `/${producerUrl}/directed-offers`;
};

export const redirectMyOffers = (producerUrl: string) => {
  window.location.href = `/${producerUrl}/my-offers`;
};

export const calculateMinBySaleType = (
  selectedTypeSale: string,
  packingSelected: Packing,
  prefrerQuantity?: number,
  preferQauntityActive?: boolean,
) => {
  if (checkUndefined(selectedTypeSale, packingSelected)) {
    if (selectedTypeSale === 'Packing') {
      return calculateMinIfPacking(
        packingSelected,
        prefrerQuantity,
        preferQauntityActive,
      );
    }
    if (selectedTypeSale === 'Layer') {
      return calculateMinIfLayer(
        packingSelected,
        prefrerQuantity,
        preferQauntityActive,
      );
    }
    return calculateMinIfTrolley(
      packingSelected,
      prefrerQuantity,
      preferQauntityActive,
    );
  }
  return Number.NaN;
};

const checkUndefined = (selectedTypeSale: string, packingSelected: Packing) =>
  typeof selectedTypeSale !== 'undefined' &&
  typeof packingSelected !== 'undefined' &&
  typeof packingSelected.maxAvailableLayersLabel !== 'undefined';

const calculateMinIfPacking = (
  packingSelected: Packing,
  prefrerQuantity?: number,
  preferQauntityActive?: boolean,
) => {
  const minimum =
    preferQauntityActive && prefrerQuantity
      ? prefrerQuantity * +packingSelected?.maxAvailablePackingsLabel
      : packingSelected?.minimumQuantity;
  const calc = minimum / +packingSelected?.maxAvailablePackingsLabel;
  return calc < 1 ? 1 : Math.ceil(calc);
};

const calculateMinIfLayer = (
  packingSelected: Packing,
  prefrerQuantity?: number,
  preferQauntityActive?: boolean,
) => {
  const minimum =
    preferQauntityActive && prefrerQuantity
      ? prefrerQuantity * +packingSelected?.maxAvailablePackingsLabel
      : packingSelected?.minimumQuantity;
  const values = packingSelected?.maxAvailableLayersLabel
    .split('x')
    .map((x) => +x);
  const calc = minimum / values.reduce((a, b) => +a * +b);
  return calc < 1 ? 1 : Math.ceil(calc);
};

const calculateMinIfTrolley = (
  packingSelected: Packing,
  prefrerQuantity?: number,
  preferQauntityActive?: boolean,
) => {
  const minimum =
    preferQauntityActive && prefrerQuantity
      ? prefrerQuantity * +packingSelected?.maxAvailablePackingsLabel
      : packingSelected?.minimumQuantity;
  const values = packingSelected?.maxAvailableTrolleysLabel
    .split('x')
    .map((x) => +x);
  const calc = minimum / values.reduce((a, b) => +a * +b);
  return calc < 1 ? 1 : Math.ceil(calc);
};

export const generateSaleTypeParam = (saleTypes: string[]) => {
  let queryParam = ``;
  saleTypes.forEach((saleType) => {
    queryParam += `Data.SaleTypes=${saleType}`;
  });

  return queryParam;
};
export const showAt = (value: string, limit: number) =>
  `${value.slice(0, limit)}`;

export const generateOrderParam = (formFiltersFields: FormFiltersFields) => {
  const filters: OrdersFiltersParam = {
    'Data.Status': formFiltersFields['Data.Status'],
    'Data.SiteTradingName': formFiltersFields['Data.SiteTradingName'],
    'Data.ProductDescription': formFiltersFields['Data.ProductDescription'],
    'Data.OrderNumber': formFiltersFields['Data.OrderNumber'],
    'Data.UserName': formFiltersFields['Data.UserName'],
  };
  getSaleTypeParameters(formFiltersFields['Data.SaleTypes'], filters);
  if (formFiltersFields['Data.TypeDate'] === DateTypeFilterEnum.BUY_DATE) {
    filters['Data.SaleDate'] =
      formFiltersFields['Data.Date'].format('YYYY-MM-DD');
  }
  if (formFiltersFields['Data.TypeDate'] === DateTypeFilterEnum.DELIVERY_DATE) {
    filters['Data.DeliveryDate'] =
      formFiltersFields['Data.Date'].format('YYYY-MM-DD');
  }
  if (formFiltersFields['Data.TypeDate'] === DateTypeFilterEnum.BILLING_DATE) {
    filters['Data.BillingDate'] =
      formFiltersFields['Data.Date'].format('YYYY-MM-DD');
  }
  return filters;
};
export const getSaleTypeParameters = (
  saleType: string,
  filters: OrdersFiltersParam,
) => {
  if (saleType === SaleTypeFilterEnum.DIRECTED) {
    filters['Data.IsDirected'] = true;
  }
  if (saleType === SaleTypeFilterEnum.VOL) {
    filters['Data.IsVol'] = true;
  }
  if (saleType === SaleTypeFilterEnum.LKP) {
    filters['Data.IsTransit'] = true;
    filters['Data.IsReceived'] = true;
  }
  if (saleType === SaleTypeFilterEnum.ALL) {
    filters['Data.IsDirected'] = true;
    filters['Data.IsVol'] = true;
    filters['Data.IsTransit'] = true;
    filters['Data.IsReceived'] = true;
  }
};
export const generateCode = (oldCode: string, newCode: string) => `${oldCode}(Antigo) - ${newCode}(Novo)`

export const roundDecimals = (value: number, decimalPlaces = 2) => {
  const roundWithPlaces = parseFloat(value.toString()).toFixed(decimalPlaces)
  return parseFloat(value.toString()).toFixed(decimalPlaces)
}

export const calculateTotalShipping = (cartItem: CartItem) => cartItem.shippingFee * cartItem.unitQuantity
